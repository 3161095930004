import ProspectShowtimes from './showtimes';
import SeeOnYoutOwn from './see_on_your_own';
import AlternateShowtimes from './alternate_showtimes';

class LoadShowtimes {
  #data = {};

  allowOnlySmsConfirmation() {
    document.querySelector('#confirm_only_sms_option input').removeAttribute('disabled');
    LayoutUtils.show(document.querySelector('#confirm_only_sms_option'));
    LayoutUtils.hide(document.querySelector('#confirm_options'));
    document.querySelector('#confirm_options input').setAttribute('disabled', true);
  }

  allowAllConfirmation() {
    document.querySelector('#confirm_options input').removeAttribute('disabled');
    LayoutUtils.show(document.querySelector('#confirm_options'));
    LayoutUtils.hide(document.querySelector('#confirm_only_sms_option'));
    document.querySelector('#confirm_only_sms_option input').setAttribute('disabled', true);
  }

   setProspectTimeZoneText() {
    const timeZoneField = document.querySelector('#showing_prospect_time_zone');
    if (timeZoneField && !timeZoneField.value) {
      LayoutUtils.show(document.querySelector('#select_a_time_zone_popup_text'));
      document.querySelector('.showtime_options_menu').style.pointerEvents = 'none';
      const popup = document.querySelector('.widget_pop_up');
      popup.style.backgroundColor = 'lightgrey';
      popup.style.pointerEvents = 'none';
      popup.style.opacity = 0.3;
      document.querySelector('#available_showtimes').dispatchEvent(new CustomEvent('showtime-picker:clear'));
    }
  }

  bindEvents() {
    document.querySelector('#alternate_showtimes_link')?.addEventListener('click', (e) => {
      e.preventDefault();
      if (e.currentTarget.getAttribute('disabled') === 'disabled') {
        return false;
      }

      LayoutUtils.show(document.querySelector('#alternate_showtimes'));
      LayoutUtils.hide(document.querySelector('#see_it_on_your_own'));
      LayoutUtils.hide(document.querySelector('#available_showtimes'));
      e.currentTarget.classList.add('active');
      document.querySelector('.time_wrap .active')?.classList.remove('active');
      document.querySelector('#available_showtimes_link')?.classList.remove('active');
      document.querySelector('#see_it_on_your_own_link')?.classList.remove('active');
      this.setProspectHasProposedTimes('true');
      if (this.#data.indeed_show_pre_marketing) {
        LayoutUtils.hide(document.querySelector('.confirm_by'));
        document.querySelector('#button_container input').value = this.#data.schedule_button_label;
      }
      if (this.#data.indeed_disabled_button) {
        document.querySelector('#button_container input').removeAttribute('disabled');
      }

      return false;
    });

    document.querySelector('#available_showtimes')?.addEventListener('showtime:selected', (event) => {
      const { showtime, showtimeDisplay, surgeShowtime } = event.detail;
      document.querySelector('#showtime').value = showtime;
      document.querySelector('#shown-showtime').value = showtimeDisplay;
      document.querySelector('#surge_showtime').value = surgeShowtime;
      if (showtimeDisplay.endsWith(this.#data.now_showtime_label)) {
        document.querySelector('#calendar_lockbox_showtime').value = showtimeDisplay;
      } else {
        document.querySelector('#calendar_lockbox_showtime').value = '';
      }
    });

    document.querySelector('#see_it_on_your_own_link')?.addEventListener('click', (e) => {
      e.preventDefault();
      if (e.currentTarget.getAttribute('disabled') === 'disabled') {
        return false;
      }

      if (JSON.parse(this.#data.lockbox_check_in_listing)) {
        this.allowOnlySmsConfirmation();
      }

      LayoutUtils.show(document.querySelector('#see_it_on_your_own'));
      LayoutUtils.hide(document.querySelector('#available_showtimes'));
      LayoutUtils.hide(document.querySelector('#alternate_showtimes'));
      LayoutUtils.hide(document.querySelector('#choose_mode_message_container'));
      document.querySelectorAll('.question.only_for_lockbox').forEach(q => {
        LayoutUtils.show(q);
        q.querySelectorAll('input, select, textarea').forEach((el) => el.removeAttribute('disabled'));
      });
      new ProspectVerificationMethod().render();
      e.currentTarget.classList.add('active');
      document.querySelector('.time_wrap .active')?.classList.remove('active');
      document.querySelector('#available_showtimes_link')?.classList.remove('active');
      document.querySelector('#alternate_showtimes_link')?.classList.remove('active');
      const showingOnLockbox = document.querySelector('#showing_on_lockbox');
      if (showingOnLockbox) {
        showingOnLockbox.value = 'true';
      }
      if (this.#data.indeed_show_pre_marketing) {
        if (this.#data.display_confirmation_options) {
          LayoutUtils.show(document.querySelector('.confirm_by'));
        }
        document.querySelector('#button_container input').value = this.#data.schedule_button_label;
      }

      if (this.#data.indeed_disabled_button) {
        document.querySelector('#button_container input').removeAttribute('disabled');
      }

      return false;
    });

    document.querySelector('#available_showtimes_link')?.addEventListener('click', (e) => {
      e.preventDefault();
      if (JSON.parse(this.#data.lockbox_check_in_listing)) {
        if (this.#data.listing_lockbox_accompanied || JSON.parse(this.#data.lockbox_for_brokers)) {
          this.allowAllConfirmation();
        } else {
          this.allowOnlySmsConfirmation();
        }
      }

      LayoutUtils.hide(document.querySelector('#alternate_showtimes'));
      LayoutUtils.hide(document.querySelector('#choose_mode_message_container'));
      LayoutUtils.hide(document.querySelector('#see_it_on_your_own'));

      if (this.#data.render_prospect_verification_method) {
        new ProspectVerificationMethod().render();
      } else if (this.#data.questions_only_for_lockbox) {
        document.querySelectorAll('.question.only_for_lockbox').forEach(q => {
          LayoutUtils.show(q);
          q.querySelectorAll('select, textarea, input').forEach((el) => el.removeAttribute('disabled', false));
        });

        new ProspectVerificationMethod().render();
      } else {
        document.querySelectorAll('.question.only_for_lockbox').forEach(q => {
          LayoutUtils.hide(q);
          q.querySelectorAll('input, select, textarea').forEach((el) => {
            el.setAttribute('disabled', true);
          });
        });
      }

      LayoutUtils.show(document.querySelector('#available_showtimes'));
      document.querySelector('#alternate_showtimes_link')?.classList.remove('active');
      document.querySelector('#see_it_on_your_own_link')?.classList.remove('active');
      this.setProspectHasProposedTimes('');
      const showingOnLockbox = document.querySelector('#showing_on_lockbox');
      if (showingOnLockbox) {
        showingOnLockbox.value = '';
      }
      e.currentTarget.classList.add('active');
      if (this.#data.indeed_show_pre_marketing) {
        LayoutUtils.hide(document.querySelector('.confirm_by'));
        document.querySelector('#button_container input').value = 'Request a Showing';
      }
      if (this.#data.indeed_disabled_button) {
        document.querySelector('#button_container input').setAttribute('disabled', true);
      }
      return false;
    });
  }

  setProspectHasProposedTimes(value) {
    const field = document.querySelector('#showing_prospect_has_proposed_times');
    if (field) {
      field.value = value;
    }
  }

  init(data) {
    this.#data = data;
    const showtimesBar = document.querySelector('.listing-showtimes-bar');
    if (showtimesBar) {
      showtimesBar.innerHTML = this.#data.showtimes_bar;
    }
    const listingLabel = document.querySelector('.listing-label');
    if (listingLabel) {
      listingLabel.innerHTML = this.#data.listing_label;
    }

    if (document.querySelectorAll('.showtime_options').length > 0) {
      document.querySelector('.showtime_options').innerHTML = this.#data.showtime_options;
      document.querySelector('.info#post_schedule_info').innerHTML = this.#data.post_schedule_info;
      document.querySelector('#button_container').innerHTML = this.#data.button_container;
    }

    this.bindEvents();

    showingFormObject.init();

    const picker = document.querySelector('#available_showtimes');
    TimepickerBuilder.init(picker);

    if (data.showtime) {
      if (picker) {
        picker.dispatchEvent(new CustomEvent('showtime-picker:setShowtime', { detail: { showtime: data.showtime } }));
      }
    }

    if (JSON.parse(this.#data.ask_prospect_time_zone)) {
      this.setProspectTimeZoneText();
      document.querySelector('#showing_prospect_time_zone').addEventListener('change', () => {
        document.querySelectorAll('#showtime, #shown-showtime').forEach(el => el.value = '');
        const tzValue = document.querySelector('#showing_prospect_time_zone').value;
        if (tzValue) {
          LayoutUtils.show(document.querySelector('.js-showtimes-loader'));
          LayoutUtils.hide(document.querySelector('.js-showtimes-table'));

          fetch(`${this.#data.listing_showtimes_path}?${new URLSearchParams({prospect_time_zone: tzValue})}`)
            .then(FetchUtils.checkResponseStatus)
            .then(response => response.json())
            .then(data => {
              new ProspectShowtimes().init(data);
              const popup = document.querySelector('.widget_pop_up');
              popup.style.backgroundColor = 'white';
              popup.style.pointerEvents = 'auto';
              popup.style.opacity = 1;
              document.querySelector('.showtime_options_menu').style.pointerEvents = 'auto';
              LayoutUtils.hide(document.querySelector('#select_a_time_zone_popup_text'));

              const alternateShowtimes = document.querySelector('.js-alternate-showtimes');
              if (alternateShowtimes) {
                new AlternateShowtimes().init(alternateShowtimes);
              }
              document.querySelector('#available_showtimes_link').click();
            })
            .finally(() => {
              LayoutUtils.show(document.querySelector('.js-showtimes-table'));
              LayoutUtils.hide(document.querySelector('.js-showtimes-loader'));
            })
            .catch(err => FetchUtils.handleResponseError(err));
        } else {
          this.setProspectTimeZoneText();
        }
      });
    }

    if (JSON.parse(this.#data.prospect_has_proposed_times)) {
      this.setProspectHasProposedTimes('true');
    }

    if (JSON.parse(this.#data.choose_mode_message_container)) {
      LayoutUtils.hide(document.querySelector('#available_showtimes'));
      LayoutUtils.hide(document.querySelector('#see_it_on_your_own'));
      LayoutUtils.show(document.querySelector('#choose_mode_message_container'));
    } else if (JSON.parse(this.#data.click_see_it_on_your_own_link)) {
      document.querySelector('#see_it_on_your_own_link')?.click();
    } else if (JSON.parse(this.#data.listing_lockbox_accompanied)) {
      if (document.querySelector('#showing_on_lockbox').value == 'true') {
        document.querySelector('#see_it_on_your_own_link')?.click();
      } else {
        document.querySelector('#available_showtimes_link')?.click();
      }
    } else if (JSON.parse(this.#data.lockbox_for_brokers)) {
      document.querySelector('#see_it_on_your_own_link')?.click();
    } else {
      if (document.querySelector('#showing_prospect_has_proposed_times')?.value == 'true') {
        document.querySelector('#alternate_showtimes_link')?.click();
      } else {
        document.querySelector('#available_showtimes_link')?.click();
      }
    }

    if (JSON.parse(this.#data.hide_available_showtimes)) {
      LayoutUtils.hide(document.querySelector('#available_showtimes'));
      document.querySelector('#alternate_showtimes_link')?.click();
    }

    const sameAgentNotification = document.querySelector('#same_agent_notification');
    if (sameAgentNotification) {
      if (this.#data.show_agent_notification) {
        sameAgentNotification.innerHTML = this.#data.same_agent_message;
        LayoutUtils.show(sameAgentNotification);
      } else {
        sameAgentNotification.innerHTML = '';
        LayoutUtils.hide(sameAgentNotification);
      }
    }

    const seeYourOwnWrapper = document.querySelector('.js-see-it-on-your-own');
    if (seeYourOwnWrapper) {
      new SeeOnYoutOwn().init(seeYourOwnWrapper);
    }

    const alternateShowtimes = document.querySelector('.js-alternate-showtimes');
    if (alternateShowtimes) {
      new AlternateShowtimes().init(alternateShowtimes);
    }

    document.dispatchEvent(new CustomEvent('prospect-page:showtimes-loaded'));
  }
}

export default LoadShowtimes;
